@font-face {
  font-family: 'DNV Display Regular';
  src: url('Fonts/DNVDisplay-Regular-Web.woff2') format('woff2'), /* Super Modern Browsers */
  url('Fonts/DNVDisplay-Regular-Web.woff') format('woff'), /* Pretty Modern Browsers */
  url('Fonts/DNVDisplay-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./Fonts/DNVDisplay-Regular.svg') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Avenir Demi';
  src: url('Fonts/avenir-demi-lat-ext.eot'); /* IE9 Compat Modes */
  src: url('Fonts/avenir-demi-lat-ext.woff') format('woff'), /* Pretty Modern Browsers */
  url('Fonts/avenir-demi-lat-ext.ttf') format('truetype'), /* Safari, Android, iOS */
  url('Fonts/avenir-demi-lat-ext.svg') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Avenir Next Medium';
  src: url('Fonts/avenir-regular-lat-ext.eot'); /* IE9 Compat Modes */
  src: url('Fonts/avenir-regular-lat-ext.woff') format('woff'), /* Pretty Modern Browsers */
  url('Fonts/avenir-regular-lat-ext.ttf') format('truetype'), /* Safari, Android, iOS */
  url('Fonts/avenir-regular-lat-ext.svg') format('svg'); /* Legacy iOS */
}



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

ul {
  line-height: 1.5em;
  margin: 5px 0 15px;
  padding: 0;
}

li {
  list-style: none;
}

li > a {
  color: #b3e3f4;
}

li.arrow {
  list-style: none;
  position: relative;
  padding: 0 0 0 20px;
}

li.arrow::before {
  content: "";
  position: absolute;
  left: 0;
  top: 9px;
  height: 5px;
  width: 5px;
  border: 1px solid #8cd3ef;
  border-width: 2px 2px 0 0;
  transform: rotate(45deg);
}

li.arrow > a {
  color: #ffffff;
}

li.arrow > a:hover, li > a:hover {
  color: #009fda;
}

a:hover {
  transform: scale(1.1);
}

.result404 {
  display: grid;
}

.ant-result-title {
  order: 1;
  margin-bottom: 30px;
}

.ant-result-icon {
  order: 2;
}